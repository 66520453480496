import useUTMHandler, { useGetUtmSessionTags } from '@hooks/useUTMtags';
import { useEffect } from 'react';
import { useUserData } from 'state/userData';

const eventName = 'pageView';

const UtmTagsProvider = () => {
    const {
        utm_source,
        utm_medium,
        utm_campaign,
        utm_content,
        utm_placement,
        utm_term,
    } = useGetUtmSessionTags();

    const { user, isFetched } = useUserData();
    const userId = user?.id || 'anonymous';

    useUTMHandler();

    useEffect(() => {
        if (!isFetched) return;

        window.dataLayer = window.dataLayer || [];

        window.dataLayer.push({
            event: eventName,
            userId,
            utm_source,
            utm_medium,
            utm_campaign,
            utm_content,
            utm_placement,
            utm_term,
        });
    }, [
        utm_campaign,
        utm_source,
        utm_medium,
        isFetched,
        utm_content,
        utm_placement,
        utm_term,
    ]);

    return null;
};

export default UtmTagsProvider;
